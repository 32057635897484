<template>
    <div>
        <!-- 顶部工具条 -->
        <el-row>
            <el-col :span='24'>
                <el-form :inline="true">
                    <el-form-item label='关键字：'>
                        <el-input v-model='filters.title' placeholder='请输入标题' clearable @clear='handleClear'></el-input>
                    </el-form-item>
                    <el-form-item label='启用标识:'>
                        <el-select v-model="filters.isEnable" placeholder="请选择" clearable @clear='handleClear'>
                            <el-option
                            v-for="item in options2"
                            :key="item.value"
                            :label="item.label"
                            :value="item.value">
                            </el-option>
                        </el-select>
                    </el-form-item>
                    <el-form-item>
                        <toolbar :buttonList="buttonList" @callFunction="callFunction"></toolbar>
                    </el-form-item>
                </el-form>
            </el-col>
        </el-row>
        <!-- 列表 -->
        <el-row :gutter='24'>
            <el-col :span='17'>
                <el-table
                border
                :data="tableData"
                :header-cell-style='headerStyle'
                :cell-style='cellStyle'
                v-loading='listLoading'
                v-if='isAlive'
                @current-change='selectCurrentRow'
                highlight-current-row
                style="width: 100%">
                    <el-table-column type='index' label="序号" width="60"></el-table-column>
                    <el-table-column prop='Title' label="项目名称" min-width="300" show-overflow-tooltip></el-table-column>
                    <el-table-column prop='Content' label="项目内容" show-overflow-tooltip min-width="180"></el-table-column>
                    <!-- <el-table-column prop='IsHtml' label="是否Html格式" >
                        <template slot-scope='scope'>
                            <el-tag :type='scope.row.IsHtml == true ? "primary" : "warning"'>{{scope.row.IsHtml == true ? '是' : '否'}}</el-tag>
                        </template>
                    </el-table-column> -->
                    <el-table-column prop='IsRelation' min-width="100" label="是否关联" >
                        <template slot-scope='scope'>
                            <el-tag :type='scope.row.IsRelation == true ? "primary" : "warning"'>{{scope.row.IsRelation == true ? '是' : '否'}}</el-tag>
                        </template>
                    </el-table-column>
                    <el-table-column prop='IsEnable' min-width="100" label="启用标识" >
                        <template slot-scope='scope'>
                            <el-tag :type='scope.row.IsEnable == true ? "success" : "error"'>{{scope.row.IsEnable == true ? '启用' : '禁用'}}</el-tag>
                        </template>
                    </el-table-column>
                    <el-table-column prop='CreateUserName' min-width="100" label="创建者" show-overflow-tooltip></el-table-column>
                    <el-table-column prop='Remarks' label="备注" min-width="100" show-overflow-tooltip></el-table-column>
                </el-table>
                <el-pagination
                    @size-change="handleSizeChange"
                    @current-change="handleCurrentChange"
                    :current-page="pages.pageIndex"
                    :page-sizes="[100, 200, 300, 400]"
                    :page-size="pages.pageSize"
                    layout="total, sizes, prev, pager, next, jumper"
                    :total="pages.dataCount"
                    class="pageBar"
                >
                </el-pagination>
            </el-col>
            <el-col :span='7' >
                <el-card style='max-height:580px;'>
                    <el-scrollbar wrap-style="overflow-x:hidden;">
                        <el-row style='font-weight:bold;'>
                            <el-col :span='12'>关联岗位:</el-col>
                            <!-- <el-col :span='10'>关联行业:</el-col> -->
                        </el-row>
                        <el-divider></el-divider>
                        <template v-for='(item,index) in obj'>
                            <el-row :key='index' style='margin-bottom:10px;' v-if='isExist'>
                                <el-col :span='24'>{{item[0].StationName}}</el-col>
                                <!-- <el-col :span='10' >
                                    <el-row v-for='gangwei in item' :key='gangwei.ID'>
                                        <el-col :span='24'>{{gangwei.TradeName}}</el-col>
                                    </el-row>  
                                </el-col>                                      -->
                            </el-row>
                        </template>
                        <el-row style='font-weight:bold;'>
                            <!-- <el-col :span='12'>关联岗位:</el-col> -->
                            <el-col :span='10'>关联行业:</el-col>
                        </el-row>
                        <el-divider></el-divider>
                        <template v-for='(item,index) in obj'>
                            <el-row :key='index' style='margin-bottom:10px;' v-if='isExist'>
                                <!-- <el-col :span='12' style='padding-right:20px;'>{{item[0].StationName}}</el-col> -->
                                <el-col :span='24' >
                                    <el-row v-for='gangwei in item' :key='gangwei.Id'>
                                        <el-col :span='24'>{{gangwei.TradeName}}</el-col>
                                    </el-row>  
                                </el-col>                                     
                            </el-row>
                        </template>
                    </el-scrollbar>
                </el-card>
            </el-col>
        </el-row>
        <!-- 新增/编辑 -->
        <el-dialog
        :title="AddOrEditTEXT"
        :visible.sync="AddOrEditVisible"
        width="65%"
        :close-on-click-modal='false'
        @close='dialogClose'>
        <el-form :model="Form" :rules="FormRules" ref="FormRef" label-width="100px">
            <el-form-item label="项目名称:" prop="Title">
                <el-input v-model="Form.Title"></el-input>
            </el-form-item>
            <!-- <el-form-item label="是否Html:" prop='IsHtml'>
                <el-switch v-model="Form.IsHtml" active-color="#13ce66" inactive-color="#c9c9c9"></el-switch>
            </el-form-item> -->
            <!-- <el-form-item label="项目内容:" prop="Content">
                <el-input v-model="Form.Content" type='textarea'></el-input>
            </el-form-item> -->
            <el-form-item label="项目内容:" prop="Content">
                <editor id="editor_id" height="250px" width="99%"
                    :content.sync="editorText"
                    :afterChange="afterChange()"
                    :uploadJson="uploadTempPic"
                    :pasteType="1"
                    :extraFileUploadParams="{token: token}"
                    :loadStyleMode="false"
                    @on-content-change="onContentChange"
                >
                </editor>
            </el-form-item>
            <el-form-item label="是否关联:" prop='IsRelation'>
                <el-switch v-model="Form.IsRelation" active-color="#13ce66" inactive-color="#c9c9c9"></el-switch>
            </el-form-item>
            <el-form-item label="是否启用:" prop='IsEnable'>
                <el-select v-model="Form.IsEnable" placeholder="请选择">
                    <el-option
                    v-for="item in options2"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value">
                    </el-option>
                </el-select>
            </el-form-item>
            <el-form-item label="备注:" prop="Remarks">
                <el-input v-model="Form.Remarks" type='textarea'></el-input>
            </el-form-item>
        </el-form>
        <span slot="footer" class="dialog-footer">
            <el-button @click="AddOrEditVisible = false">取 消</el-button>
            <el-button type="primary" @click.native="formSubmit">确 定</el-button>
        </span>
        </el-dialog>
        <!-- 岗位对话框 -->
        <el-dialog
        title="选择岗位"
        :visible.sync="gangWeiDialogVisible"
        width="30%"
        :close-on-click-modal='false'>
        <div>
            <el-checkbox-group v-model="checkGangWeiList">
                <el-checkbox v-for="item in gangWeiArr" :key="String(item.Id)"
                    :label="item.Id.toString()">
                    {{item.Name}}
                </el-checkbox>
            </el-checkbox-group>
        </div>
        <span slot="footer" class="dialog-footer">
            <el-button @click="gangWeiDialogVisible = false">取 消</el-button>
            <el-button type="primary" @click="nextStep" :loading="nextLoading">下一步</el-button>
        </span>
        </el-dialog>

        <!-- 下一步 -->
        <el-dialog
        title="选择行业"
        :visible.sync="hangYeDialogVisible"
        width="30%"
        :close-on-click-modal='false'>
        <div>
            <el-tabs tab-position="top" v-for='(item,index) in resolveRepeat(arr)' :key='index' v-model='activeName'>
                <el-tab-pane :label="item.Name" :name='item.Id.toString()'>
                    <template v-if='item.hangYeArr'>
                        <el-checkbox-group v-model="item.checkHangYeList">
                            <el-checkbox v-for="items in resolveRepeat(item.hangYeArr)" :key="String(items.Id)"
                                :label="items.Id.toString()">
                                {{items.Name}}
                            </el-checkbox>
                        </el-checkbox-group>
                    </template>
                    <template v-else>
                        <el-checkbox-group v-model="item.checkHangYeList">
                        </el-checkbox-group>
                    </template>
                </el-tab-pane>
            </el-tabs>
        </div>
        <span slot="footer" class="dialog-footer">
            <el-button @click="hangYeDialogVisible = false">取 消</el-button>
            <el-button type="primary" @click="submit">确定</el-button>
        </span>
        </el-dialog>
    </div>
</template>

<script>
import { getButtonList } from "../../promissionRouter";
import Toolbar from "../../components/Toolbar";
import util from "../../../util/date";
import Qs from 'qs'
import { getExamProjectExplainListPage,addExamProjectExplain,updateExamProjectExplain,deleteExamProjectExplain,getExamProjectExplainList,addExamProjectExplainSTRelation,getDataDictionaryList,uploadImagesUrl } from '@/api/api'
import editor from '../../components/kindeditor.vue'
import api from '@/api'
export default {
    components: { Toolbar,editor },
    data(){
        return {
            token: '',
            uploadTempPic: '',
            editorText: '', // 双向同步的变量
            editorTextCopy: '',  // content-change 事件回掉改变的对象
            isExist:true,
            fixedArr:[],
            getEditGangweiHangye:[],
            isGetRelationData:false,
            obj:{},
            activeName:'',
            arr:[],
            buttonList:[],
            // 筛选项
            filters:{
                title:'',
                isEnable:''
            },           
            tableData:[], // 列表数据
            listLoading:false,
            pages:{
                dataCount:0,
                // pageIndex:Number(localStorage.getItem('currentPage')) ? Number(localStorage.getItem('currentPage')) : 1,
                pageIndex: 1,
                pageSize:100
            },
            isAlive:true, // 控制列表及时渲染
            AddOrEditTEXT:'新增', // 新增/编辑文本
            AddOrEditVisible:false, // 新增/编辑对话框显示与隐藏
            isAdd:true,
            currentRow:{}, // 当前行数据
            // 表单数据
            Form:{
                Title:'',
                Content:'',
                IsHtml: false,
                IsRelation:false,
                IsEnable:true,
                Remarks:''
            },
            FormRules:{
                Title:[{required:true,message:'请输入项目名称',trigger:'blur'}],
                Content:[{required:true,message:'请输入项目内容',trigger:'blur'}],
                // isHtml:[{ trigger: "change" }],
                // isRelation:[{trigger:'change'}],
                // isEnable:[{trigger:'blur'}],
                Remarks:[{message:'请输入备注',trigger:'blur'}]
            },
            options:[
                {value:true,label:'是'},
                {value:false,label:'否'}
            ],
            options1:[
                {value:true,label:'是'},
                {value:false,label:'否'}
            ],
            options2:[
                {value:true,label:'启用'},
                {value:false,label:'禁用'}
            ],
            gangWeiDialogVisible:false,
            hangYeDialogVisible:false,
            nextLoading:false, // 控制下一步是否加载

            gangWeiArr:[], // 所有岗位
            hangYeArr:[], // 所有行业
            checkGangWeiList:[], // 选择的岗位
            checkHangYeList:[], // 选择的行业

            stListData:[],
            editRelation:false, // 选择当前行，展示当前行关联关系
        }
    },
    methods:{   
        // 富文本编辑相关
        onContentChange (val) {
            this.editorTextCopy = val;
            this.Form.Content = val;
        },
        afterChange () {
            
        },
        // 列表居中
        headerStyle(){
            return 'text-align:center;'
        },  
        cellStyle(){
            return 'text-align:center;'
        },
        callFunction(item) {
            this[item.Func].apply(this, item);
        },
        // 列表及时渲染
        reload(){
            this.isAlive = false
            this.$nextTick(() => this.isAlive = true)
        },
        // 清空筛选框内容，重新获取数据
        handleClear(){
            this.getData()
        },
        // 分页处理
        handleSizeChange(value){
            this.pages.pageSize = value
            this.getData()
        },
        handleCurrentChange(value){
            this.pages.pageIndex = value
            localStorage.setItem('currentPage',this.pages.pageIndex)
            this.getData()
        },
        // 选中当前行
        selectCurrentRow(row){
            this.checkGangWeiList = []
            this.getEditGangweiHangye = []
            this.isGetRelationData = false
            this.obj = {}
            if(row == null){
                return
            }else{
                this.isExist = true
                this.row = row
                this.currentRow = row
                // 获取关联关系
                if(this.editRelation == false && row.IsRelation == true){
                    getExamProjectExplainList({examProjectExplainId:this.currentRow.Id}).then(res => {
                        if(res.data.Success){
                            var data = res.data.Response                          
                            var obj = {}
                            data.forEach(item => {
                                if(Object.keys(obj).indexOf('' + item.StationId) === -1){
                                    obj[item.StationId] = []
                                }
                                obj[item.StationId].push(item)
                            })
                            this.obj = obj 
                        }else{return}
                    })
                }else{
                    this.editRelation = true
                    this.obj = {}
                    return
                }
            }
        },
        // 查询
        getExamProjectExplainListPage(){
            this.pages.pageIndex = 1
            this.getData()
        },
        // 新增
        handleAdd(){
            this.isAdd = true
            this.AddOrEditTEXT = '新增'
            this.AddOrEditVisible = true 
            this.Form = {
                Title:'',
                Content:'',
                IsHtml: false,
                IsRelation:false,
                IsEnable:true,
                Remarks:''
            }
        },
        // 编辑
        handleEdit(){
            var row = Object.assign({},this.currentRow)
            if(JSON.stringify(row) == '{}'){
                this.$message.warning('请选择要编辑的一行数据')
            }else{
                this.AddOrEditTEXT = '编辑'
                this.AddOrEditVisible = true
                this.isAdd = false
                this.Form = {
                    Title:row.Title,
                    Content:row.Content,
                    IsHtml: false,
                    IsRelation:row.IsRelation,
                    IsEnable:row.IsEnable,
                    Remarks:row.Remarks
                }
                this.editorText = row.Content
            }
        },
        // 删除
        handleDel(){
            var row = Object.assign({},this.currentRow)
            if(JSON.stringify(row) == '{}'){
                this.$message.warning('请选择要删除的一行数据')
            }else{
                this.$confirm('此操作将永久删除该文件, 是否继续?', '提示', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning'
                }).then(() => {
                    deleteExamProjectExplain({Id:this.currentRow.Id}).then(res => {
                        if(res.data.Success){
                            this.reload()
                            this.getData()
                            this.isExist = false
                            this.obj = {} ////
                            this.$message.success(res.data.Message)
                        }else{
                            this.$message.error(res.data.Message)
                        }
                    }).catch(() => {})
                }).catch(() => {})
            }
        },
        // 编辑关联信息
        addExamProjectExplainSTRelation(){
            // this.arr = []
            // this.checkGangWeiList = []
            // // 清空选择的行业岗位
            // this.checkGangWeiList = []
            this.getEditGangweiHangye = []
            this.obj = {}
            this.gangWeiArr = []
            this.editRelation = true
            var row = Object.assign({},this.currentRow)
            if(JSON.stringify(row) == '{}'){
                this.$message.warning('请选择要编辑关联关系的一行数据')
            }else if(row.IsRelation == null || row.IsRelation == false){
                this.$message.warning('未关联，不可编辑！')
            }else{
                // 得到行业岗位相关信息
                var params = {
                    dataType:1
                }
                this.getJob(params)
                var params1 = {
                    examProjectExplainId:row.Id
                }
                getExamProjectExplainList(params1).then(res => {
                    if(res.data.Success){
                        if(res.data.Response.length>0){
                            this.isGetRelationData = true
                            var data = res.data.Response
                            var getGangWeiIDs = []
                            data.forEach(item => {
                                getGangWeiIDs.push(String(item.StationID))
                            })
                            this.checkGangWeiList = Array.from(new Set(getGangWeiIDs))
                            this.gangWeiDialogVisible = true
                            /////                         
                            var obj = {}
                            data.forEach(item => {
                                if(Object.keys(obj).indexOf('' + item.StationId) === -1){
                                    obj[item.StationId] = []
                                }
                                obj[item.StationId].push(item)
                            })
                            this.obj = obj   
                            for(var i in this.obj){
                                this.getEditGangweiHangye.push(this.obj[i])                                       
                            }
                        }else{
                            this.gangWeiDialogVisible = true
                            this.checkGangWeiList = []
                        }                      
                    }else{}
                })
            }
        },
        // 新增/编辑表单提交
        formSubmit(){
            this.$refs['FormRef'].validate((valid) => {
                if(valid){
                    if(this.isAdd){
                        this.AddOrEditVisible = false
                        var params = {...this.Form} 
                        params.Content = encodeURIComponent(this.Form.Content)
                        this.addFormSubmit(params)                                          
                    }else{
                        this.AddOrEditVisible = false
                        var params = {...this.Form}
                        params.Content = encodeURIComponent(this.Form.Content)
                        params.Id = this.currentRow.Id
                        this.editFormSubmit(params)
                    }
                }else{return}
            })
        },
        addFormSubmit(params){
           addExamProjectExplain(params).then(res => {
                if(res.data.Success){                
                    this.getData()
                    this.$message.success(res.data.Message)
                }else{
                    this.$message.error(res.data.Message)
                }
            }).catch(() => {})
        },
        editFormSubmit(params){
            updateExamProjectExplain(params).then(res => {
                if(res.data.Success){
                    this.getData()
                    this.$message.success(res.data.Message)                  
                }else{
                    this.$message.warning(res.data.Message)
                }
            }).catch(() => {})     
            this.reload()
        },
        // 对话框关闭，清空表单
        dialogClose(){
            this.$refs['FormRef'].resetFields()
        },
        // 得到列表数据
        getData(){
            this.listLoading = true
            var params = {
                pageIndex:this.pages.pageIndex,
                pageSize:this.pages.pageSize,
                title:this.filters.title ? this.filters.title : '',
                isEnable:this.filters.isEnable
            }
            getExamProjectExplainListPage(params).then(res => {
                this.listLoading = false
                if(res.data.Success){
                    this.tableData = res.data.Response.Data
                    this.pages.dataCount = res.data.Response.DataCount
                }else{
                    this.$message.error(res.data.Message)
                }
            }).catch(() => {this.listLoading = false})
        },
        // 得到岗位行业
        getJob(params){
            getDataDictionaryList(params).then(res => {
                switch(params.dataType){
                    case 1:
                        if(res.data.Success){
                            var list = res.data.Response  
                            // 岗位为禁用时，不可显示
                            list = list.filter(item => {
                                return item.IsEnable == true
                            })                     
                            this.gangWeiArr = list
                        }else{return}
                        break;
                    case 0:
                        if(res.data.Success){
                            var list = res.data.Response  
                            // 行业为禁用时，不可显示
                            list = list.filter(item => {
                                return item.IsEnable == true
                            })
                            for(var i in list){
                                this.hangYeArr.push(list[i])
                            }
                        }else{return}
                        break;
                }     
            })
        }, 
        // 下一步
        nextStep(){
            if(this.checkGangWeiList.length == 0){
                this.$message.warning('请选择岗位!')
            }else{
                this.nextLoading = true
                var params = {dataType:0}
                this.getJob(params)
                this.gangWeiDialogVisible = false
                this.hangYeDialogVisible = true
                this.nextLoading = false  
                var arr = []
                this.gangWeiArr.map((item,index) => {
                    for(var i = 0;i<this.checkGangWeiList.length;i++){
                        if(item.Id == this.checkGangWeiList[i] && item.IsRelation == true){
                            var obj = {}
                            obj.Id = item.Id
                            obj.Name = item.Name
                            obj.checkHangYeList = this.$set(item,'choose',[])
                            obj.hangYeArr = this.$set(item,'hangYeArr',this.hangYeArr)
                            arr.push(obj)   ////
                        }else if(item.Id == this.checkGangWeiList[i] && item.IsRelation == false){
                            var obj = {}
                            obj.Id = item.Id
                            obj.Name = item.Name
                            obj.checkHangYeList = this.$set(item,'choose',[])
                            arr.push(obj)
                        }
                    }
                    return arr
                }) 
                if(this.isGetRelationData == true){
                    arr.forEach(item => {
                        this.getEditGangweiHangye.forEach(items => {
                            items.forEach(i => {
                                if(item.Id == i.StationId){
                                    item.checkHangYeList.push(String(i.TradeId))
                                }
                            })
                        })
                    })  
                    this.activeName = arr[0].Id.toString()
                    this.arr = arr
                    return this.arr
                }else{
                    this.activeName = arr[0].Id.toString()
                    this.arr = arr
                    return this.arr
                } 
            }                    
        },
        submit(){
            var flag = 0
            this.arr.forEach(item => {
                if(item.hasOwnProperty('hangYeArr')){
                    if(item.checkHangYeList.length == 0){
                         flag += 1
                    }else{
                        for(var i = 0;i<item.checkHangYeList.length;i++){
                            var obj = {
                                StationId:item.Id,
                                TradeId:Number(item.checkHangYeList[i])
                            }
                            this.stListData.push(obj)
                        }
                    }
                }else{
                        var obj = {
                            StationId:item.Id,
                            TradeId:null
                        }
                        this.stListData.push(obj)
                }
            })
            if(flag!=0){
                this.$message.warning('已关联岗位至少选择关联一个行业')                      
            }else{
               var params = {
                    ExamProjectExplainId:this.currentRow.Id,
                    Stlist:this.resolveHangYeRepeat(this.stListData)
                }
                addExamProjectExplainSTRelation(params).then(res => {
                    this.hangYeDialogVisible = false
                    if(res.data.Success){
                        this.$message.success('编辑关联关系成功')
                        // 清空选择的行业岗位
                        this.checkGangWeiList = []
                        this.getEditGangweiHangye = []
                        this.isGetRelationData = false
                        this.obj = {}
                        setTimeout(() => {
                            this.$router.go(0)
                            this.pages.pageIndex = localStorage.getItem('currentPage')
                        },1500)
                    }else{
                        this.$message.error('编辑关联关系失败')
                        this.checkGangWeiList = []
                        this.getEditGangweiHangye = []
                        this.obj = {}
                        this.isGetRelationData = false
                        setTimeout(() => {
                            this.$router.go(0)
                            this.pages.pageIndex = localStorage.getItem('currentPage')
                        },1500)

                    }
                }).catch(() => {
                    this.$message.error('编辑关联关系失败')
                    this.checkGangWeiList = []
                    this.getEditGangweiHangye = []
                    this.isGetRelationData = false
                    this.obj = {}
                    setTimeout(() => {
                            this.$router.go(0)
                            this.pages.pageIndex = localStorage.getItem('currentPage')
                    },1500)
                })       
            }
        },
        // 获取token
        getToken() {
            return localStorage.getItem("Token")
        },
        resolveRepeat(oldArr){
            var allArr = [];//新数组
            for(var i=0;i<oldArr.length;i++){
            　　var flag = true
            　　for(var j=0;j<allArr.length;j++){
            　　　　if(oldArr[i].Id == allArr[j].Id){
            　　　　　　flag = false
            　　　　};
            　　};
            　　if(flag){
            　　　　allArr.push(oldArr[i])
            　　}
            }
            return allArr
        },
        // 关联行业去重
        resolveHangYeRepeat(oldArr){
            var allArr = [];//新数组
            for(var i=0;i<oldArr.length;i++){
            　　var flag = true
            　　for(var j=0;j<allArr.length;j++){
            　　　　if(oldArr[i].stationId == allArr[j].stationId && oldArr[i].tradeId == allArr[j].tradeId){
            　　　　　　flag = false
            　　　　}
            　　};
            　　if(flag){
            　　　　allArr.push(oldArr[i])
            　　}
            }
            return allArr
        },
    },
    created() {
        this.uploadTempPic = api.uploadImgByEditor
        this.token = this.getToken()
    },
    mounted(){
        localStorage.removeItem('currentPage')
        this.getData()
        let routers = window.localStorage.router
        ? JSON.parse(window.localStorage.router)
        : [];
        this.buttonList = getButtonList(this.$route.path, routers);
    },
    
}
</script>

<style lang="stylus" scoped>
.pageBar{
	height :60px;
}
    .el-textarea>>>.el-textarea__inner {
        min-height 150px!important;
    }
    ul,li{
        list-style:none;
    }
    .el-divider--horizontal{
        margin:10px 0!important;
    }
    .el-scrollbar{
        height:380px;
    }
</style>
<style>
    .el-tooltip__popper {
        max-width: 500px;
    }
</style>